<template>

  <b-modal v-model="modals.questions" :title=" $t('dashboard.product.questions') " centered content-class="card card-ecart"
    header-class="card-header" @hidden="changeModal({questions:false})" size="lg" hide-header-close>
    <ProductQuestions :productId="productInformation.id" @close="changeModal({questions:false})" />
    <template v-slot:modal-footer>
      <p class="text-right">
        <b-button variant="none" class=" mr-2" @click=" changeModal({questions:false}) "
          v-text=" $t('general.button.close') ">
        </b-button>
      </p>
    </template>
  </b-modal>
</template>

<script>
  import {
    mapState,
    mapActions,
    mapMutations
  } from 'vuex';
  import ProductQuestions from "@/components/dashboard/product/product-questions";
  export default {
    components: {
      ProductQuestions,
    },
    computed: {
      ...mapState('EcartProduct', ['modals', 'productInformation'])
    },
    methods: {
      ...mapMutations('EcartProduct', ['changeModal']),
    },
    mounted() {}
  }
</script>